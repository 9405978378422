.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &__text {
    font-weight: 800;
    font-size: $font-size-big;
    line-height: 24px;
    padding-left: 7px;
    padding-right: 9px;
    color: $color-main;

    span {
      color: $color-light;
    }
  }
}