.date-picker {
  &__modal-main {
    .react-responsive-modal-modal {
      max-width: 632px;
      width: 100%;
      padding: 32px 16px;
      @include lt-md {
        margin: 0;
      }
      @include xs {
        padding: 24px 10px;
      }
    }
  }

  &__mobile-open {
    background-color: $color-accent;
    padding: 10px 13px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: $font-size-main;
    line-height: 20px;
    justify-content: flex-start;
    color: $color-light;

    svg {
      min-width: 14px;
      margin-right: 13px;

      path {
        fill: $color-light;
      }
    }
  }

  h2 {
    font-weight: 800;
    font-size: $font-size-big;
    line-height: 30px;
    margin-bottom: 16px;
    @include xs {
      font-size: $font-size-medium;
      line-height: 22px;
    }

  }

  button.main-button {
    width: 100%;
    max-width: 160px;
    min-height: 40px;
    font-weight: 500;
    font-size: $font-size-main;
    line-height: 20px;

    &.icon {
      justify-content: center;

      svg {
        min-width: 12px;
        margin-right: 8px;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;
    @include xs {
      margin-bottom: 24px;
    }
  }

  &__button-bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .date-picker__button-clear {
      color: $color-main;
    }
  }

  &__button, &__button-clear {
    color: $color-minor;
    font-size: $font-size-main;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    @include xs {
      font-size: $font-size-small;
      line-height: 16px;
    }

    &.active {
      color: $color-accent;
    }

    &:focus-visible {
      outline: none;
    }

    margin-right: 16px;
  }

  .react-datepicker {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: none;
    font-family: $font-family-main;
    @include xs {
      flex-direction: column;
    }

    &__month-container {
      float: none;
      height: 100%;
      border: 1px solid $color-border-main;
      display: flex;
      flex-direction: column;
      min-height: 336px;
      width: 100%;

      &:not(:last-child) {
        margin-right: 8px;
      }

      @include xs {
        min-height: auto;
      }
    }

    &__current-month {
      font-weight: 800;
      font-size: $font-size-main;
      line-height: 20px;
    }

    .react-datepicker__navigation-icon::before {
      border-color: $color-accent;
      border-width: 2px 2px 0 0;
      width: 6px;
      height: 6px;
    }

    &__header {
      background: none;
      border: none;
    }

    &__day-names, &__week {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__day-name {
      color: $color-minor;
      font-size: $font-size-small;
      line-height: 16px;
      padding: 12px 6px;
      width: 40px;
      height: 40px;
      margin: 0;
    }

    &__month {
      margin: 0;
    }

    &__day {
      font-size: $font-size-small;
      line-height: 16px;
      padding: 12px 6px;
      font-weight: 500;
      width: 40px;
      height: 40px;
      margin: 0;
    }

    .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-start {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }

    .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }

    .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }

    .react-datepicker__day.react-datepicker__day--in-range {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }

    &__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
      border-radius: 0;
      background-color: $color-accent;
      opacity: 0.6;
    }

    &__day {
      @media ($hover) {
        &:hover {
          border-radius: 0;
        }
      }
    }

    &__day--keyboard-selected {
      @media ($hover) {
        &:hover {
          background-color: $color-accent;
        }
      }
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      border-radius: 0;
      background-color: $color-light;
      color: $color-main;
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
    }
  }

  &.allDate {
    .react-datepicker {
      .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }

      .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-start {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }

      .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }

      .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }

      .react-datepicker__day.react-datepicker__day--in-range {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }

      &__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        border-radius: 0;
        background-color: $color-accent;
        opacity: 0.6;
      }

      .react-datepicker__day.react-datepicker__day--keyboard-selected {
        border-radius: 0;
        background-color: $color-light;
        color: $color-main;
      }

      .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
        background-color: $color-light;
        color: $color-main;
        border-radius: 0;
      }
    }
  }

}