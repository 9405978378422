.withdraw-table {
  &.table {
    grid-template-columns:
            minmax(150px, 0.5fr)
            minmax(150px, 0.5fr)
            minmax(150px, 0.5fr);
    //minmax(150px, 3.4fr);
    @include xs {
      grid-template-columns:
              minmax(100px, 0.5fr)
              minmax(120px, 2fr)
              minmax(80px, 0.5fr);
      padding: 0 16px;
    }

    @include xxs {
      grid-template-columns:
              minmax(90px, 0.5fr)
              minmax(120px, 1fr)
              minmax(80px, 0.5fr);
      padding: 0 16px;
    }
  }

  tr.loader {
    display: block;
  }

  &__wrapper {
    @include xs{
      min-height: 300px;
    }
    .loader {
      text-align: center;
      margin: 20px auto 0;
    }
  }

  &__not-found {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin: 10px 0;
  }
}