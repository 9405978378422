.withdraw-table-item {
  &__time {
    color: $color-minor;
    @include xs {
      display: none;
    }
  }
  &__status {
    display: inline-flex;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 10px;
    color: $color-light;
    letter-spacing: 0.5px;
    @include xs {
      display: inline-block;
      max-width: 80px;
      padding: 2px 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_active {
      background: $color-accent;
    }
    &_approved {
      background: $color-success;
    }
    &_rejected {
      background: $color-attention;
    }
  }
}