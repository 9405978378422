.item-shop {
  background-color: $color-light;
  transition: all .3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $color-border-main;

  &__img {
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    background: $color-accent;
    align-items: center;
    justify-content: center;
    min-height: 144px;
  }

  &__bottom {
    width: 100%;
    flex: 0 0 auto;
    padding: 24px;
    @include xs {
      padding: 16px;
    }
  }

  &__name {
    color: $color-main;
    font-weight: 800;
    font-size: $font-size-medium;
    line-height: 22px;
    margin-bottom: 8px;
    width: 100%;
    @include xs {
      font-size: $font-size-main;
      line-height: 20px;
    }
  }
}