.header-sidebar {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  background: $color-accent;
  overflow-x: auto;
  position: relative;
  @include lt-xl {
    max-width: 250px;
  }
  @include xs {
    max-width: 100%;
    overflow-x: inherit;
  }

  .country-switcher {
  }

  $space-bottom-menu: 15px;

  &__user {
    &-info {
      display: block;
      margin-bottom: $space-bottom-menu;
      @media ($hover) {
        &:hover .header-sidebar__user-name {
          color: $color-accent;
        }
        &:hover .header-sidebar__user-avatar {
          border: 2px solid $color-bright;
        }
      }

      &.header-sidebar__link_active {
        .header-sidebar__user-avatar {
          border: 2px solid $color-bright;
        }

        .header-sidebar__user-name {
          color: $color-bright
        }
      }
    }

    $avatar-width: 50px;


    &-avatar {
      width: $avatar-width;
      height: $avatar-width;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 2px solid transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-name {
      color: $color-main;
      font-size: $font-size-accent;
      font-weight: 600;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    padding: 16px 0 0 16px;
    flex: 1 0;

    &-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
    }

    &-buttons {
      display: flex;
      flex: 1 1 auto;
    }

    &-logout {
      padding: 10px 16px;
      background: $color-main;
      border-radius: 6px;
      color: $color-light;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(45, 60, 88, 0.2);
      transition: .3s ease;
      font-weight: 600;
      @media ($hover) {
        &:hover {
          background: $color-light;
          color: $color-main;
        }
      }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-light;
    font-weight: 500;
    font-size: $font-size-main;
    line-height: 20px;
    padding: 14px 16px;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;

    svg {
      min-width: 14px;
      margin-right: 17px;
    }

    &_active {
      color: $color-main;
      background-color: $color-light;

      svg {

        path {
          fill: $color-accent;
        }
      }
    }

    @media ($hover) {
      &:hover {
        color: $color-main;
        background-color: $color-light;

        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }
  }

  &__wrapper-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 72px;
    padding: 0 28px;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.25);
  }

  &__logo {
    .logo__icon-el {
      display: none;
    }

    .logo__text {
      padding-left: 12px;
      padding-right: 0;
      @include xs {
        padding-left: 15px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 28px;
    @include lt-xl {
      flex-direction: column;
      padding: 15px;
    }

    &-menu {
      width: 50%;
      font-size: $font-size-main;

      &:first-child {
        @include lt-xl {
          order: 2;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
        }

      }

      @include lt-xl {
        width: 100%;
        font-size: $font-size-small;
      }

      &:not(:first-child) {
        .header-sidebar__footer-link {
          color: $color-light;
          padding-left: 17px;
          @include lt-xl {
            padding-left: 0;
          }
        }
      }
    }

    &-link {
      display: block;
      line-height: 20px;
      color: $color-main;

      &:not(:last-child) {
        margin-bottom: 24px;
        @include lt-xl {
          margin-bottom: 10px;
        }
      }

      svg {
        rect {
          fill: $color-main;
        }
      }
    }

    ._year {
      @include lt-xl {
        order: 2;
      }
    }
  }

  & .header-top {
    background-color: transparent;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.25);
    padding: 16px 24px;

    &__user-info {
      width: 50%;
    }

    &__user-name {
      font-size: $font-size-small;
      line-height: 16px;
    }

    &__user-avatar {
      width: 36px;
      height: 36px;
      padding: 2px;
      background: $color-light;
    }

    &__balance {
      width: 50%;
      margin-right: 0;
      display: inline-block;
      font-size: $font-size-small;
      line-height: 16px;


      &-icon {
        display: none;
      }

      span {
        font-weight: 800;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        b {
          color: $color-light;
          font-weight: normal;
          margin-left: 8px;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }
}