.cookie-option {
  &__inner {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__name {
    font-size: $font-size-medium;
    line-height: 1;
    font-weight: bold;
    @include xs {
      font-size: $font-size-main;
    }
  }

  &__switch {
    width: 50px;
    padding: 1px 2px;
    border-radius: 25px;
    background: $color-light;
    border: 2px solid $color-border-main;
    font-size: 0;
    line-height: 0;
    margin-right: 5px;
    cursor: pointer;
    transition: all .3s linear;

    span {
      display: inline-flex;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $color-main;
      @include xs {
        height: 16px;
        width: 16px;
      }
    }
    &_active {
      text-align: right;
      border-color: $color-accent;
      background: $color-accent;
      span {
        background: $color-light;
      }
    }

    &_required {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}