.withdraw-page {

  &__title-container {
    padding: 0 16px;
  }

  &.content-page {
    overflow-x: hidden;
  }
  .content-page__wrapper {
    @include xs {
      padding: 16px 0;
    }
  }
}