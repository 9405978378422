.profile-page {

  #input-upload {
    display: none !important;
  }

  &__avatar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @media ($hover) {
      &:hover .profile-page__avatar-img {
        border: 1px solid $color-accent;
      }
    }
  }

  &__avatar-img {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 1px solid $color-border-main;
    margin-right: 16px;

    img {
      padding: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__avatar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__avatar-item-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 269px;
    width: 100%;
  }

  &__avatar-text {
    color: $color-minor;
    font-size: $font-size-small;
    line-height: 16px;
  }

  &__avatar-delete {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $font-size-small;
    line-height: 16px;
    @media ($hover) {
      &:hover {
        opacity: $hover-opacity;
      }
    }

    svg {
      margin-right: 8px;

    }
  }

  &__avatar-text-upload {
    cursor: pointer;
    @media ($hover) {
      &:hover {
        opacity: $hover-opacity;
      }
    }
  }

  &__avatar-text-upload {
    color: $color-accent;
    font-size: $font-size-main;
    font-weight: 800;
    line-height: 20px;
    padding-bottom: 12px;
    box-shadow: inset 0px -1px 0px $color-border-main;
    width: 100%;
  }

  &__avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}