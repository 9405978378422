.withdraw-funds{
  &__modal {
    padding: 32px 16px;
    max-width: 432px;
    width: 100%;
    margin: 0;
    @include xs {
      width: 96%;
    }
  }
  &__balance {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    span {
      font-weight: bold;
      margin-left: 5px;
    }
    &-icon {
      margin-right: 10px;
    }
  }

  &__form {
    margin-top: 20px;
  }

  &__input {
    &-container {
      margin-bottom: 32px;
    }
    &-name {
      font-size: $font-size-small;
      color: $color-minor;
      line-height: 16px;
      margin-bottom: 10px;
    }
    &-error-text {
      font-size: $font-size-small;
      line-height: 16px;
      color: $color-attention;
      margin-top: 5px;
    }
  }

  &__success {
    margin-top: 20px;
    &-text {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }

    &-btn {

    }
  }
}