.how-it-work-page {

  &__wrapper-content {
    margin-left: 32px;
    background-color: $color-light;
    @include lt-md {
      margin-left: 0;
    }
  }

  &__content {
    padding: 96px 0 96px 0;
    position: relative;
    z-index: 3;
    max-width: 71%;
    @include xl-max {
      padding: 40px 0 40px 0;
      max-width: 80%;
    }
    @include lt-xl {
      padding: 40px 40px 40px 0;
      max-width: 100%;
    }
    @include lt-lg {
      max-width: 100%;
      padding: 40px;
    }
    @include xs {
      padding: 32px;
    }
    @include xxs-max {
      padding: 10px;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      font-weight: 500;
      padding-left: 96px;
      max-width: 570px;
      margin-bottom: 16px;
      margin-top: 0;
      @include xl-max {
        padding-left: 40px;
      }
      @include lt-lg {
        padding-left: 0;
        font-size: $font-size-big;
        line-height: 30px;
      }


      b {
        font-weight: 800;
      }
    }
  }


  &__list-title {
    padding-left: 96px;
    font-size: $font-size-medium;
    line-height: 22px;
    margin-bottom: 48px;
    @include lt-lg {
      padding-left: 0;
      margin-bottom: 16px;
    }
    @include xs {
      font-size: $font-size-main;
      line-height: 20px;
      margin-bottom: 62px;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -32px;
    @include lt-lg {
      margin-left: 0;
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 48px;
      @include xs {
        margin-bottom: 62px;
      }
    }

    @include xs {
      position: relative;
    }

    &-text {
      font-size: $font-size-medium;
      line-height: 22px;
      position: relative;
      width: calc(100% - 302px);
      @include xl-max {
        width: calc(100% - 232px);
      }
      @include lt-lg {
        margin-top: 20px;
        width: 100%;
      }
      @include xs {
        width: 100%;
      }
      @include xs {
        margin-top: 12px;
        font-size: $font-size-main;
        line-height: 20px;
        position: static;
      }
    }

    &-step {
      background-color: $color-accent;
      border-radius: 4px 4px 4px 0;
      min-width: 42px;
      color: $color-light;
      padding: 2px 6px;
      position: absolute;
      top: -45px;
      left: 0px;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;

      &:before {
        content: '';
        background-image: url("../../icon/icon-triangle-step.svg");
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        bottom: -8px;
      }

    }
  }

  &__image-container {
    font-size: 0;
    line-height: 0;
    border: 1px solid $color-accent;
    padding: 7px;
    background: $color-light;
    max-width: 272px;
    margin-right: 32px;
    @include xl-max {
      max-width: 200px;
    }
    @include lt-lg {
      margin-right: 0;
    }
    @include xs {
      text-align: center;
      max-width: 272px;
    }

    img {
      width: 100%;
    }
  }
}