html[lang=ru] {
  .sign-up-page__earn-more h1 {
    font-size: 60px;
    line-height: 60px;
    @include lt-xl {
      font-size: 40px;
      line-height: 40px;
    }
    @include xs {
      font-size: 34px;
      line-height: 34px;
    }
  }

  .policy-page h1 {
    font-size: 40px;
    line-height: 40px;
    @include xl-max {
      font-size: 34px;
      line-height: 34px;
    }

    @include lt-xl {
      font-size: 28px;
      line-height: 28px;
    }

    @include lt-md {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .cookie-modal__advanced button.main-button {
    font-size: $font-size-small;
    padding: 6px;
  }

}