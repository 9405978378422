.not-found-page {
  display: flex;
  width: 100%;
  @include xs {
    min-height: 350px;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include xs {
      padding: 50px 20px;
    }
  }

  &__title {
    font-size: $font-size-large;
    line-height: $line-height-min;
    font-weight: 600;
    margin-bottom: 20px;
    color: $color-accent;
  }

  &__text {
    font-size: $font-size-accent;

    a {
      color: $color-accent;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}