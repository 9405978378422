.short-link {

  &__container {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    line-height: 1.1;
    min-height: 32px;
    cursor: copy;
    svg {
      margin-right: 5px;
    }
    span {
      max-width: calc(100% - 17px);
      text-align: left;
    }

  }
  &__get-btn {
    font-size: $font-size-main;
    line-height: 16px;
    display: inline-flex;
    background: $color-accent;
    border-radius: 25px;
    padding: 6px 12px;
    border: 2px solid $color-accent;
    color: $color-light;
    cursor: pointer;
    transition: all .3s linear;
    position: relative;
    &:focus-visible {
      border-color: $color-accent;
      outline: none;
    }
    @media ($hover) {
      &:hover {
        background: transparent;
        color: $color-accent
      }
    }

    &_loading {
      cursor: default;
      pointer-events: none;
      > span {
        opacity: 0;
      }
    }
  }

  &__btn-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}