.default-input{
  margin-bottom: 32px;
  max-width: 358px;
  width: 48%;

  @include xs {
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
  }
}