.product-item {
  width: 100%;
  background-color: #ffffff;
  transition: all .3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E4E7EA;
  padding: 24px;
  cursor: pointer;
  @include xs {
    padding: 16px;
  }

  &__name {
    font-size: $font-size-main;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__image-container {
    width: 100%;
    height: 160px;
    position: relative;
    margin-bottom: 10px;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }

  &__price {
    text-align: center;
    width: 100%;
    margin-bottom: 5px;

    span {
      font-weight: bold;
    }
  }

  &__bottom {
    flex: 1;
    display: flex;

    width: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__modal {
    max-width: 740px;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 2fr;
    text-align: left;
    @include xs {
      display: block;
      padding: 14px 0;
    }
    &-image {
      text-align: center;
      img {
        width: 100%;
        max-width: 300px;
      }
    }
    &-price {
      font-size: $font-size-accent;
      margin-bottom: 20px;
      @include xs {
        font-size: $font-size-main;
      }
      span {
        font-weight: bold;
      }
    }
    &-name {
      font-size: $font-size-big;
      font-weight: bold;
      margin-bottom: 20px;
      @include xs {
        font-size: $font-size-medium;
      }
    }
  }
}