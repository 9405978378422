.cookie-modal {
  display: flex;
  z-index: 10;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  background: $color-light;
  box-shadow: inset 0px 3px 0px #d7dadd;

  button.main-button {
    font-size: $font-size-main;
    line-height: 20px;
    min-height: 0;
    min-width: 190px;
    padding: 10px;
    @include xs {
      font-size: $font-size-small;
      line-height: 16px;
      min-width: 120px;
    }
  }

  &__text-container {
    padding-left: 10px;
  }

  &__text {
    font-size: $font-size-small;
    line-height: 14px;
    max-width: 425px;

    span {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      color: $color-accent;
      cursor: pointer;

      svg {
        margin-left: 5px;

        path {
          fill: $color-accent;
        }
      }
    }
  }

  &__advanced {
    z-index: 15;
    display: block;
    max-width: 480px;
    width: 100%;
    max-height: 100vh;
    background: $color-light;
    border: 2px solid $color-border-main;
    padding: 14px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow-y: auto;
    font-size: $font-size-main;
    line-height: 16px;
    @include xs {
      font-size: $font-size-small;
      line-height: 14px;
    }

    &-container {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 10;
    }

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-title {
      margin-bottom: 16px;
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    button.main-button {
      font-size: $font-size-main;
      line-height: 20px;
      min-height: 0;
      padding: 10px;
      width: 48%;
      @include xs {
        font-size: $font-size-small;
        line-height: 16px;
      }
    }
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 20px;
  }

  &__policy-link {
    cursor: pointer;
    display: block;
    width: 48%;
    color: $color-main;
    @media ($hover) {
      &:hover {
        color: $color-accent;

        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }

    svg {
      display: inline-flex;
      align-items: center;
      margin-left: 5px;
      position: relative;
      top: 1px;
      path {
        fill: $color-main;
      }
    }
  }
}