.nickname {
  width: 100%;
  max-width: 747px;
  margin-bottom: 32px;
  margin-top: 32px;

  &__form {
    &-field {
      margin-bottom: 32px;
      max-width: 358px;
      width: 100%;

      @include xs {
        max-width: 100%;
        margin-bottom: 24px;
      }
    }

    button.main-button {
      width: 100%;
      max-width: 360px;
      min-height: 40px;
      font-weight: 500;
      font-size: $font-size-main;
      line-height: 20px;

      &.icon {
        justify-content: center;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  &__required {
    margin: -15px 0 32px;
    max-width: 358px;
    width: 100%;
    padding: 6px 10px;
    border-radius: 5px;
    border: 2px solid $color-attention;
    background: rgba(243, 78, 78, 0.3);
    font-size: $font-size-main;
    font-weight: bold;
    text-align: center;
    @include xs {
      max-width: 100%;
    }
  }
}