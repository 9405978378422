.balance-page__table {

  &.table {
    grid-template-columns:
    minmax(150px, 0.5fr)
    minmax(150px, 0.5fr)
    minmax(150px, 0.5fr)
    minmax(150px, 0.5fr)
    minmax(150px, 1fr);
  }


  tr.loader {
    display: block;
  }

  &-wrapper {
    @include xs{
      min-height: 300px;
    }
  }

  &-time {
    color: $color-minor;
  }

  &-not-found {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin: 10px 0;
  }

  &-product-list {
    white-space: inherit!important;
    div {
      font-size: $font-size-small;
      line-height: 14px;
    }
    div:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }

}