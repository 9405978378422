.products-page {
  &__loader {
    padding: 50px 20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    svg g {
      fill: $color-accent;
    }
  }

  &__sub-title {
    max-width: 680px;
    width: 100%;
  }

  .content-page__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  &__empty {
    padding: 10px;
    &-label {
      font-size: $font-size-accent;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-btn.main-button {
      background-color: $color-accent;
      max-width: 192px;
      width: 100%;
      color: $color-light;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 20px;
      min-height: 40px
    }
  }
}