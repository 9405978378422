.girl-paralax {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 100%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  &__girl-img {
    max-width: 100vh;
    height: 100vh;
    @include lg {
      max-width: 90vh;
      height: 90vh;
    }
  }

  .fade-in-down {
    animation: fadeInDown 2s ease;

  }

  .fade-in-up {
    animation: fadeInUp 2s ease;

  }

  .bag {
    position: absolute;
    bottom: 0px;
    left: 18%;

    img {
      max-width: 300px;
    }
  }

  .phone {
    position: absolute;
    top: 27%;
    left: 30%;

    img {
      max-width: 100px;
    }
  }

  .shoe {
    position: absolute;
    bottom: 23%;
    right: 19%;

    img {
      max-width: 150px;
    }
  }

  .back-shoe {
    position: absolute;
    bottom: 33%;
    left: 16%;


    img {
      max-width: 150px;
    }
  }

  .pod-o {
    position: absolute;
    top: 16%;
    left: 37%;

    img {
      max-width: 23px;
    }
  }

  .pod-t {
    position: absolute;
    top: 21%;
    left: 31%;

    img {
      max-width: 18px;
    }
  }

  .lipstick-o {
    position: absolute;
    top: 37%;
    right: 25%;

    img {
      max-width: 50px;
    }
  }

  .lipstick-t {
    position: absolute;
    top: 44%;
    right: 21%;

    img {
      max-width: 30px;
    }
  }

  .watch {
    position: absolute;
    top: 47%;
    right: 35%;

    img {
      max-width: 90px;
    }
  }

  .particle-on {
    position: absolute;
    bottom: 2%;
    left: 36%;

    img {
      max-width: 10px;
    }
  }

  .particle-tw {
    position: absolute;
    top: 56%;
    left: 26%;

    img {
      max-width: 8px;
    }
  }

  .particle-th {
    position: absolute;
    top: 51%;
    left: 20%;

    img {
      max-width: 8px;
    }
  }

  .particle-fo {
    position: absolute;
    top: 63%;
    left: 26%;

    img {
      max-width: 8px;
    }
  }

  .particle-fi {
    position: absolute;
    top: 61%;
    left: 41%;

    img {
      max-width: 20px;
    }
  }

  .particle-si {
    position: absolute;
    top: 55%;
    left: 42%;

    img {
      max-width: 20px;
    }
  }

  .particle-se {
    position: absolute;
    top: 69%;
    right: 36%;

    img {
      max-width: 20px;
    }
  }

  .particle-ei {
    position: absolute;
    top: 62%;
    right: 26%;

    img {
      max-width: 10px;
    }
  }

  .particle-ni {
    position: absolute;
    bottom: 22%;
    right: 29%;

    img {
      max-width: 5px;
    }
  }

  .particle-te {
    position: absolute;
    bottom: 6%;
    right: 31%;

    img {
      max-width: 30px;
    }
  }

  .particle-o-el {
    position: absolute;
    top: 51%;
    right: 47%;

    img {
      max-width: 8px;
    }
  }

  .particle-o-tw {
    position: absolute;
    top: 38%;
    right: 47%;

    img {
      max-width: 6px;
    }
  }

  .particle-o-th {
    position: absolute;
    top: 41%;
    right: 45%;

    img {
      max-width: 12px;
    }
  }

  .particle-o-fo {
    position: absolute;
    top: 33%;
    right: 51%;

    img {
      max-width: 18px;
    }
  }

  .particle-o-fi {
    position: absolute;
    top: 20%;
    left: 26%;

    img {
      max-width: 10px;
    }
  }

  .particle-o-si {
    position: absolute;
    top: 9%;
    left: 34%;

    img {
      max-width: 8px;
    }
  }

  .particle-o-se {
    position: absolute;
    top: 12%;
    left: 41%;

    img {
      max-width: 12px;
    }
  }

  .particle-o-ei {
    position: absolute;
    top: 24%;
    right: 31%;

    img {
      max-width: 8px;
    }
  }

  .particle-o-ni {
    position: absolute;
    top: 26%;
    right: 41%;

    img {
      max-width: 13px;
    }
  }

  .particle-o-twe {
    position: absolute;
    top: 18%;
    right: 25%;

    img {
      max-width: 8px;
    }
  }

  .particle-o-tw-on {
    position: absolute;
    top: 20%;
    right: 34%;

    img {
      max-width: 18px;
    }
  }

  .particle-o-tw-tw {
    position: absolute;
    top: 30%;
    right: 21%;

    img {
      max-width: 5px;
    }
  }

  .particle-o-tw-th {
    position: absolute;
    top: 44%;
    right: 36%;

    img {
      max-width: 5px;
    }
  }

  .particle-o-tw-fo {
    position: absolute;
    top: 38%;
    left: 42%;

    img {
      max-width: 5px;
    }
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translatey(10px);
    -moz-transform: translatey(10px);
    -o-transform: translatey(10px);
    transform: translatey(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}