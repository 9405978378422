.statistics-page__table {
  &-wrapper {
    height: 100%;
  }

  &.table {
    grid-template-columns:
    minmax(100px, 1.1fr)
    minmax(100px, 1.5fr)
    minmax(100px, 3.33fr)
    minmax(100px, 1.67fr);

    .details {
      text-align: right;
      cursor: pointer;
    }
  }

  &-details {
    text-align: right !important;
  }
}