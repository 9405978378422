.header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $color-light;
  box-shadow: inset 0px -1px 0px $color-border-main;
  padding: 16px 11px;

  @include xs {
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex-direction: row;
    width: calc(100% - 92px);
    justify-content: flex-end;
    align-items: center;
    @include lt-lg {
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }


  &__balance {
    margin-right: 24px;
    display: flex;
    align-items: center;
    color: $color-main;
    font-size: $font-size-main;
    line-height: 20px;

    svg {
      margin-right: 10px;
    }

    span {
      font-weight: 800;
    }
  }

  &__button {
    &.main-button {
      background-color: $color-accent;
      max-width: 192px;
      width: 100%;
      color: $color-light;
      padding: 10px 20px;
      font-size: $font-size-main;
      line-height: 20px;
      min-height: 40px;
    }
  }

  &__user {

    &-info {
      padding-right: 24px;
      margin-right: 24px;
      border-right: 1px solid $color-border-main;
      @include lt-lg {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        width: 100%;
      }
      @media ($hover) {
        &:hover .header-top__user-name {
          color: $color-accent;
        }
      }

      //&.header-top__link_active {
      //  .header-top__user-avatar {
      //    border: 2px solid $color-bright;
      //  }
      //
      //  .header-top__user-name {
      //    color: $color-bright
      //  }
      //}
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    $avatar-width: 40px;


    &-avatar {
      width: $avatar-width;
      height: $avatar-width;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid $color-accent;
      padding: 4px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-name {
      color: $color-main;
      font-size: $font-size-main;
      line-height: 20px;
      font-weight: 800;
    }
  }
}