// media aliases and breakpoints

$screen-sm-min: 577px;
$screen-md-min: 769px;
$screen-lg-min: 993px;
$screen-xl-min: 1201px;

$screen-xxs-max: 340px;
$screen-xs-max: 576px;
$screen-sm-max: 768px;
$screen-md-max: 992px;
$screen-lg-max: 1200px;
$screen-xl-max: 1400px;

// media devices
@mixin xxs {
  @media screen and (max-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media lt queries
@mixin xxs-max {
  @media screen and (max-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl-max {
  @media screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}