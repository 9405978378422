.notifications {
  width: 100%;
  padding: 8px;

  &__wrapper {
    cursor: pointer;
    background-color: $color-bright;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: pulse-notifications 2s infinite;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-light;
    font-size: $font-size-main;
    font-weight: 500;
    @include sm {
      font-size: $font-size-small;
      line-height: 16px;
    }
    @include xs {
      font-size: $font-size-small;
      line-height: 16px;
    }

    svg {
      margin-right: 16px;

      path {
        fill: $color-attention;
      }
    }

    &:not(:last-child) {
      color: $color-light;
      padding-right: 10px;
      @include xs {
        padding-left: 0;
      }
    }
  }


  &__icon-arrow {
    margin-left: 16px;

    path {
      fill: $color-light !important;
    }
  }

  &__icon-notification {
    min-width: 20px;
  }
}

@keyframes pulse-notifications {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 78, 78, 0.5);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}