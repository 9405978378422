.footer-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: $color-accent;
  @include lt-md {
    padding: 32px;
  }
  @include xxs-max {
    padding: 10px;
  }


  &__menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    flex-wrap: wrap;
    padding: 12px 0;
    @include xl-max {
      padding: 0;
    }

    a {
      @media ($hover) {
        &:hover {
          opacity: $hover-opacity;
        }
      }

      svg {
        rect {
          fill: black;
        }
      }
    }

    &:not(:first-of-type) {
      a, ._year {
        color: $color-main;
      }
    }

    a, ._year {
      text-decoration: none;
      font-weight: 400;
      font-size: $font-size-main;
      line-height: 20px;
      color: $color-light;


      @include lt-lg {
        padding: 10px 0;
      }

      @include xl-max {
        padding: 5px 0;
      }
      @include lt-md {
        font-size: $font-size-small;
        line-height: 16px;
      }

      &:not(:last-of-type) {
        margin-right: 32px;
        @include lg {
          margin-right: 10px;
        }
        @include lt-md {
          margin-right: 14px;
        }
      }

    }


    & ._year {
      color: $color-minor;
      margin-right: 32px;
      @include lg {
        margin-right: 10px;
      }
      @include lt-md {
        margin-right: 0;;
      }
      @include xs {
        width: 100%;
      }
    }
  }

  &__button-support {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;
    font-size: $font-size-main;
    line-height: 20px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin-bottom: 16px;
    @media ($hover) {
      &:hover {
        color: #00aff0;
      }
    }

    svg {
      padding-right: 8px;

      rect {
        fill: $color-main;
      }
    }
  }
}