.pages-info-layout {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.3fr 144px;
  background-image: url("../../images/sign-page-bg.png");
  background-size: cover;
  height: 100%;
  overflow-x: hidden;

  &.how-it-work-page {
    grid-template-columns: 1fr 1.32fr 144px;
    @include lt-xl {
      grid-template-columns: 1fr 1.32fr;
    }

    .pages-info-layout__content {
      background-color: transparent;
    }
  }

  @include lt-xl {
    grid-template-columns: 1fr 1.3fr;
  }
  @include lt-md {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 4;
    @include lt-lg {
      justify-content: space-between;
      gap: 15px;
    }

    .logo {
      margin-right: 127px;
      @include lt-lg {
        margin-right: 0;
      }
    }
  }

  &__wrapper-title {
    position: relative;
    display: grid;
    padding-left: 64px;
    grid-template-rows: 144px 1fr 144px;
    @include xl-max {
      padding-left: 20px;
    }
    @include lt-lg {
      padding: 0 32px 50px;
      grid-template-rows: 90px 1fr;
    }
    @include xl-max {
      padding: 0 20px 16px;
    }
    @include xs {
      padding: 0 32px 32px 32px;
    }
    @include xxs-max {
      padding: 0 10px 32px 10px;
    }

    .pages-info-layout__button-back-mobile {
      margin-bottom: 16px;

      a.main-button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 3;
    max-width: 585px;
    width: 100%;
    padding-left: 80px;
    padding-right: 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    @include xl-max {
      max-width: 400px;
      padding-left: 20px;
    }
    @include lg {
      max-width: 350px;
    }
    @include lt-md {
      max-width: 80%;
      padding-left: 0;
    }
    @include lt-sm {
      padding-left: 0;
      padding-top: 0;
    }
    @include xs {
      max-width: 100%;
    }


    h1 {
      color: $color-light;
      font-weight: 200;
      font-size: 64px;
      line-height: 64px;
      margin-bottom: 32px;

      b {
        font-weight: 800;
      }

      @include lt-xl {
        font-size: 50px;
        line-height: 50px;
      }
      @include lt-md {
        font-size: 40px;
        line-height: 40px;
      }
      @include xs {
        margin-bottom: 0;
      }
    }


    .main-button {
      display: inline-flex;
      width: auto;
      min-width: 160px;
      padding: 10px 20px;
      min-height: 40px;
      span {
        margin-left: 5px;
      }
      @include xs {
        font-size: $font-size-main;
        padding: 10px 20px;
      }

    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include lt-md {
      background-color: $color-light;
      padding: 32px;
    }
    @include xxs-max {
      padding: 10px;
    }
  }

  &__footer-menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    flex-wrap: wrap;
    padding: 12px 0;
    @include xl-max {
      padding: 0;
    }

    a {
      @media ($hover) {
        &:hover {
          color: $color-main;
        }
      }

    }

    &:not(:first-of-type) {
      a {
        @media ($hover) {
          &:hover {
            color: $color-light;
          }
        }
      }

      a, .pages-info-layout__footer-year {
        color: $color-main;
      }
    }

    ._question {
      svg {
        rect {
          fill: black;
        }
      }

      margin-left: 32px;
      @include lg {
        margin-left: 10px;
      }
      @include lt-md {
        margin-left: 14px;
      }
    }

    a, .pages-info-layout__footer-year, ._question {
      text-decoration: none;
      font-weight: 400;
      font-size: $font-size-main;
      line-height: 20px;
      color: $color-light;

      @include lt-lg {
        padding: 10px 0;
      }

      @include xl-max {
        padding: 5px 0;
      }
      @include lt-md {
        font-size: $font-size-small;
        line-height: 16px;
        color: $color-main;
      }

      &:not(:last-of-type) {
        margin-right: 32px;
        @include lg {
          margin-right: 10px;
        }
        @include lt-md {
          margin-right: 14px;
        }
      }

    }


    & .pages-info-layout__footer-year {
      color: $color-minor;
      margin-right: 32px;
      @include lg {
        margin-right: 10px;
      }
      @include lt-md {
        margin-right: 0;
        order: 6;
        color: $color-minor !important;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    @include lt-md {
      background-color: $color-accent;
      padding: 32px;
    }
    @include xxs-max {
      padding: 32px 10px;
    }

    button.main-button, a.main-button {
      font-size: $font-size-main;
      line-height: 20px;
      font-weight: 500;

      &.button-light {

        color: $color-accent;

        svg {
          path {
            fill: $color-accent;
          }
        }
      }

      svg.rotate-arrow {
        transform: rotate(180deg);
      }

      color: $color-light;
      border-color: $color-light;

      svg {
        path {
          fill: $color-light;
        }
      }

      &:not(:first-of-type) {
        margin-left: 16px;
      }
    }
  }

  &__wrapper-content {
    padding: 16px 0;
    overflow: hidden;
    @include lt-md {
      padding: 0;
    }
  }

  &__content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-light;
  }

  &__wrapper-girl {
    position: relative;
  }

  &__img-girl {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 60px;
    height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    @include xl-max {
      left: 115px;
    }

    img {
      max-width: 100vh;
      height: 100vh;
    }
  }

  &__button-support {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;
    font-size: $font-size-main;
    line-height: 20px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin-bottom: 16px;
    @media ($hover) {
      &:hover {
        color: #00aff0;
      }
    }

    svg {
      padding-right: 8px;
    }
  }

  &__button-back-mobile {
    padding: 0;

    a.main-button {
      background-color: $color-accent;
      color: $color-light;
      border-radius: 0;
      padding: 32px;

      &.icon {
        justify-content: flex-start;
      }

      svg.rotate-arrow {
        transform: rotate(180deg);
        margin-right: 16px;
      }

    }
  }

  & .footer-main {
    background-color: $color-light;

    &__button-support {
      rect {
        fill: $color-accent;
      }
    }

    &__menu {
      a, ._year {
        color: $color-main;
      }

      ._year {
        color: $color-minor !important;
        @include lt-md {
          width: 100%;
        }
      }
    }
  }

  &__partners-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    img {
      max-width: 140px;
      width: 100%;
      margin: 10px;
      @include xs {
        max-width: 100px;
      }
    }
  }

  &__contacts {
    padding-top: 20px;
    &-icon {
      width: 28px;
    }
    &-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      h3 {
        margin-top: 0;
      }
    }
    &-text {
      padding-left: 10px;
    }
  }
}