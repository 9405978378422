.country-switcher {
  max-width: 92px;
  width: 100%;

  font-weight: 500;
  font-size: $font-size-small;
  line-height: 16px;
  cursor: pointer;
  //.css-tlfecz-indicatorContainer {
  //
  //}
  //
  //.css-1gtu0rj-indicatorContainer {
  //  padding: 0 8px;
  //}


}