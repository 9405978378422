.sign-up-page {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url("../../images/sign-page-bg.png");
  background-size: cover;
  height: 100%;
  overflow-x: hidden;

  $grid-template-rows: 144px 1fr 144px;
  @include lt-lg {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    overflow-x: hidden;
  }

  &__more {
    position: relative;
    display: grid;
    padding-left: 64px;
    grid-template-rows: $grid-template-rows;
    @include xl-max {
      padding-left: 20px;
    }
    @include lt-lg {
      padding: 0 32px 50px;
      grid-template-rows: 90px 1fr;
    }
    @include xxs-max {
      padding: 0 15px 50px;
    }
  }

  &__auth {
    background-color: $color-light;
    display: grid;
    justify-content: flex-end;
    padding-right: 64px;
    grid-template-rows: $grid-template-rows;
    @include xl-max {
      padding-right: 20px;
    }
    @include lt-lg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0 10px;
      position: relative;
    }


    .sign-up-page__footer-menu {
      justify-content: flex-end;
      @include lt-lg {
        justify-content: center;
      }

      a {
        color: $color-main;

        &:not(:last-of-type) {
          margin-right: 0;
        }

        @media ($hover) {
          &:hover {
            color: $color-accent;
          }
        }

        &:not(:first-of-type) {
          margin-left: 56px;
          @include lg {
            margin-left: 30px;
          }
          @include lt-lg {
            margin-left: 10px;
          }

        }
      }
    }

    .sign-up-page__menu {
      justify-content: flex-end;
      @include lt-lg {
        justify-content: center;
        margin-bottom: 32px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 4;
    @include lt-lg {
      justify-content: space-between;
      gap: 15px;
    }

    .logo {
      margin-right: 127px;
      @include lt-lg {
        margin-right: 0;
      }
    }
  }

  &__wrapper-form {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 80px;
    @include xl-max {
      padding-right: 40px;
    }
    @include lt-lg {
      padding: 0 32px;
      justify-content: center;
    }
    @include xxs-max {
      padding: 0 15px 10px;
    }
  }

  &__earn-more {
    position: relative;
    z-index: 3;
    max-width: 480px;
    width: 100%;
    padding-left: 80px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    @include xl-max {
      max-width: 400px;
      padding-left: 20px;
    }
    @include lg {
      max-width: 350px;
    }
    @include lt-sm {
      max-width: 160px;
      padding-left: 0;
      padding-top: 50px;
    }

    h1 {
      color: $color-light;
      font-weight: 200;
      font-size: $font-size-large;
      line-height: 80px;
      margin-bottom: 24px;
      @include lt-xl {
        font-size: 50px;
        line-height: 50px;
      }
    }

    h2 {
      font-weight: 800;
      font-size: $font-size-big;
      line-height: 30px;
      margin-bottom: 32px;
      color: $color-main;
      @include lt-xl {
        margin-bottom: 50px;
      }
      @include xs {
        font-size: $font-size-medium;
        line-height: 22px;
      }
    }

    .main-button {
      max-width: 200px;
      font-weight: 400;
      @include xs {
        font-size: $font-size-main;
        padding: 10px 20px;
      }

    }
  }

  &__footer-menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    flex-wrap: wrap;
    @media screen and (max-width: 1450px) {
      padding-top: 33px;
      align-items: flex-start;
    }
    @include lt-lg {
      padding: 0 10px;
      align-items: center;
    }

    a {
      @media ($hover) {
        &:hover {
          color: $color-main;
        }
      }
    }

    a, .sign-up-page__footer-year {
      text-decoration: none;
      font-weight: 300;
      font-size: $font-size-main;
      line-height: 20px;
      color: $color-light;

      @include lt-lg {
        padding: 10px 0;
      }

      @include lt-md {
        padding: 5px 0;
      }


      &:not(:last-of-type) {
        margin-right: 56px;
        @include xl-max {
          margin-right: 30px;
        }
      }

    }


    & .sign-up-page__footer-year {
      color: $color-minor;
      margin-right: 0;
      margin-left: 56px;
      @include lg {
        margin-left: 30px;
      }
      @include lt-lg {
        width: 100%;
        margin-left: 0;
        text-align: center;
      }
    }
  }

  &__button-support {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;
    font-size: $font-size-main;
    line-height: 20px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    @media ($hover) {
      &:hover {
        color: #00aff0;
      }
    }

    svg {
      padding-right: 8px;
    }
  }

  &__girl-mobile {
    position: absolute;
    right: -10%;
    top: 15%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include lt-md {
      right: -200px;
    }
    @include xs {
      right: -257px;
    }
  }


  &__privacy {
    &-text {
     &_error {
       color: $color-attention;
       border-bottom: 1px solid $color-attention;
     }
    }
    &-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: $font-size-small;
      a {
        color: $color-accent;
      }
    }
    &-mark {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid $color-minor;
      position: relative;
      margin-right: 6px;
      cursor: pointer;
      background: $color-light;
      span {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        path {
          fill: $color-accent;
        }
      }
      &_error {
        border-color: $color-attention;
      }
    }
  }
}

