.feedback-form {
  &__wrapper-content {
    @include lg {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__content {
    padding: 139px 0 50px 96px;
    position: relative;
    z-index: 3;
    max-width: 71%;
    @include lt-xl {
      padding: 40px;
      max-width: 100%;
    }
    @include xs {
      padding: 32px;
    }
    @include xxs-max {
      padding: 32px 10px 32px 10px;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      font-weight: 400;
      margin-bottom: 16px;

      b {
        font-weight: 800;
      }

      @include xs {
        font-size: $font-size-big;
        line-height: 30px;
      }
    }
  }

  &__form-title {
    font-size: $font-size-main;
    line-height: 22px;
    margin-bottom: 48px;
    @include xs {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  &__form {
    max-width: 464px;
    width: 100%;
    @include lt-md {
      max-width: 100%;
    }
  }

  &__form-field {
    margin-bottom: 32px;
  }

  button.main-button {
    max-width: 160px;
    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}