@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import 'vars';
@import 'modules/media-queries';
@import 'modules/loading';
@import 'modules/pages-info-layout';
@import 'modules/logo';
@import 'modules/home-page';
@import 'modules/header-sidebar';
@import 'modules/not-found-page';
@import 'modules/spinner';
@import 'modules/sign-up-page';
@import 'modules/sign-up-form';
@import 'modules/country-select';
@import 'modules/header-top';
@import 'modules/item-shop';
@import 'modules/notifications';
@import 'modules/profile-page';
@import 'modules/profile-form';
@import 'modules/info-user-page';
@import 'modules/statistics-page';
@import 'modules/statistics-table';
@import 'modules/balance-page';
@import 'modules/balance-table';
@import 'modules/faq-page';
@import 'modules/girl-paralax';
@import 'modules/how-it-works-page';
@import 'modules/policy-page';
@import 'modules/feed-back-form';
@import 'modules/info-page-faq';
@import 'modules/mobile-select-page';
@import 'modules/footerMain';
@import 'modules/date-picker-main';
@import 'modules/nickname';
@import 'modules/products-page';
@import 'modules/shop-list';
@import 'modules/product-item';
@import 'modules/short-link';
@import 'modules/withdraw-page';
@import 'modules/withdraw-funds';
@import 'modules/withdraw-table';
@import 'modules/withdraw-table-item';
@import 'modules/landFixes';
@import 'modules/cookie-modal';
@import 'modules/cookie-option';
@import 'modules/info-full-form';
@import 'modules/default-input';

body {
  height: 100%;
  font-family: $font-family-main;
  font-size: $font-size-main;
  line-height: $line-height-main;
  color: $color-main;
  padding: 0;
  margin: 0;
  background: linear-gradient(107.56deg, #ffffff 0%, #f6f9ff 23.44%, #dee3f0 42.71%, #f6f9ff 67.04%, #dee3f0 81.77%);
}

* {
  box-sizing: border-box;
}

.isHidden {
  display: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f9f9fd;
  position: relative;
}

::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  background-color: #f9f9fd;
}

.full-page {
  flex: 1 1;
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @include xs {
    height: auto;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;

  }
}

.main-container-page {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  //max-width: 1280px;
  //margin: 0 auto;
  //padding: 0 10px;
  background-color: $color-light;
  @include xs {
    flex-direction: column;
  }

  &.full {
    max-width: 100%;
    padding: 0;
    display: block;
    overflow: auto;
  }
}


.content-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  width: 75%;
  @include xs {
    width: 100%;
  }

  &__wrapper {
    padding: 48px 56px;
    @include lt-xl {
      padding: 28px 20px;
    }
    @include xs {
      padding: 32px;
    }


    h2 {
      font-weight: 800;
      font-size: $font-size-big;
      line-height: 30px;
      margin-top: 0;
      margin-bottom: 40px;
      color: $color-main;
      @include lt-xl {
        margin-bottom: 26px;
      }
      @include xs {
        font-size: $font-size-medium;
        line-height: 22px;
        margin-bottom: 24px;
      }

      &.content-page__title-shops {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include xs {
          font-size: $font-size-medium;
          line-height: 22px;
        }
      }
    }

    h3 {
      font-weight: 800;
      font-size: $font-size-medium;
      line-height: 22px;
      margin-top: 0;
      margin-bottom: 20px;
      color: $color-main;
      @include lt-xl {
        margin-bottom: 26px;
      }
      @include xs {
        font-size: $font-size-main;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }
  }
}

.chart-balance {
  padding: 48px 56px;
  border-top: 1px solid $color-border-main;
  @include lt-xl {
    padding: 28px 20px;
  }
  @include xs {
    padding: 32px 0 32px 0;
  }

  h2 {
    font-weight: 800;
    font-size: $font-size-big;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 40px;
    color: $color-main;
    @include lt-xl {
      margin-bottom: 26px;
    }
    @include xs {
      font-size: $font-size-medium;
      line-height: 22px;
      padding: 0 32px;
      margin-bottom: 24px;
    }
  }

}

h1 {
  font-size: $font-size-big;
  margin-top: 0;
  margin-bottom: 10px;
}

h2 {
  font-size: $font-size-accent;
  margin-top: 0;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

button.main-button, a.main-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 10px 24px;
  min-height: 48px;
  font-size: $font-size-medium;
  border-radius: 50px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: $color-accent;
  font-weight: 600;
  border: 1px solid $color-accent;
  position: relative;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.icon {
    justify-content: space-between;
  }

  svg.loading {
    display: none;
  }

  @media ($hover) {
    &:hover {
      opacity: $hover-opacity;
    }
  }

  &.loading {
    svg.loading {
      display: inline;
      margin-left: 10px;
      -webkit-animation: rotation 1s steps(8) infinite;
      animation: rotation 1s steps(8) infinite;
      transform-origin: 50% 50%;
    }
  }

  &.button-light {
    background-color: $color-light;
    color: $color-main;
    border-color: $color-light;
    @media ($hover) {
      &:hover {
        opacity: $hover-opacity;
      }
    }
  }

  &.button-accent {
    background-color: $color-accent;
    color: $color-light;
    border-color: $color-accent;
    @media ($hover) {
      &:hover {
        opacity: $hover-opacity;
      }
    }
  }
}

.main-button {
  &_loading {
    > span {
      opacity: 0;
    }
  }
  &__btn-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-flex;
    font-size: 0;
    align-items: center;
  }
}

@keyframes rotation {
  to {
    transform: rotate(1turn)
  }
}

label.label {
  color: $color-main;
  display: block;
  font-size: $font-size-main;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

input.input {
  padding: 0;
  border: none;
  padding-bottom: 12px;
  font-size: $font-size-main;
  border-bottom: 1px solid $color-border-main;
  border-radius: 0;
  width: 100%;

  &.no-valid-input {
    border-color: #f34e4e !important;
  }

  &:focus:not(:active) {
    border-bottom: 1px solid $color-accent;
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
}

label.label {
  font-size: $font-size-small;
  line-height: 16px;
  color: $color-minor;
  font-weight: 400;
}

textarea.textarea {
  width: 100%;
  font-size: $font-size-main;
  border: none;
  border-bottom: 1px solid $color-border-main;
  border-radius: 0;
  resize: none;
  min-height: 60px;

  &.no-valid-input {
    border-color: #f34e4e !important;
  }

  &:focus:not(:active) {
    border-bottom: 1px solid $color-accent;
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.input_error {
  color: $color-attention;
  font-size: $font-size-small;
  margin-top: 3px;
}

.no-valid-input .css-1s2u09g-control {
  border-color: $color-attention !important;
}

.react-datepicker__input-container {
  max-width: 300px;

  & input {
    width: 100%;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background-image: url("../icon/icon-date-picker.svg");
    right: 9px;
    top: 8px;
  }
}

table.table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  overflow-y: auto;
  grid-template-columns:
    minmax(150px, 1fr)
    minmax(150px, 1.67fr)
    minmax(150px, 3.33fr)
    minmax(150px, 1.67fr);
  border-top: 1px solid $color-border-main;

  thead,
  tbody,
  tr {
    display: contents;
    border-bottom: 1px solid $color-border-main;
  }

  th,
  td {
    padding: 16px 5px 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid $color-border-main;
    text-align: left;
    font-size: $font-size-main;
    line-height: 20px;
    @include xxs {
      font-size: $font-size-small;
    }
  }

  th {
    position: sticky;
    top: 0;
    text-align: left;
    color: $color-minor;
    font-size: $font-size-small;
    line-height: 16px;
    font-weight: 400;

  }


  td {
    padding-top: 16px;
    padding-bottom: 16px;
    color: $color-main;

    &.plus {
      font-weight: 800;
      color: $color-accent;
    }

    &.bold {
      font-weight: 800;
    }
  }

  tr:nth-child(even) td {
    border-bottom: 1px solid $color-border-main;
  }

  .details {
    cursor: pointer;
  }
}

.react-responsive-modal-closeButton {
  top: 6px;
  right: 6px;
}