.mobile-select-page {
  width: 100%;

  &__menu {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: left;
    flex: 1 0;

    &-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      position: relative;
      z-index: 4;
      padding: 16px 32px 0 32px;
      background-color: $color-accent;

      &.active {
        .selected-menu {
          .mobile-select-page__link {
            svg.arrow-right {
              transform: rotate(-90deg);
              -webkit-transition: all 0.6s ease;
              -moz-transition: all 0.6s ease;
              -ms-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;
              transition: all 0.6s ease;
            }
          }
        }

      }
    }

    &-buttons {
      display: flex;
      flex: 1 1 auto;
    }

    &-logout {
      padding: 10px 16px;
      background: $color-main;
      border-radius: 6px;
      color: $color-light;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(45, 60, 88, 0.2);
      transition: .3s ease;
      font-weight: 600;
      @media ($hover) {
        &:hover {
          background: $color-light;
          color: $color-main;
        }
      }
    }

    &-items {
      background-color: $color-accent;
      padding: 16px 32px 0 32px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      transition: max-height 0.5s, opacity 0.5s, visibility 0.5s ease;;

      .mobile-select-page__link {
        color: $color-light;
        background-color: transparent;

        svg {

          path {
            fill: $color-light;
          }
        }
      }

      &.active {
        visibility: visible;
        height: auto;
        max-height: 290px;
        opacity: 1;
        transition: max-height 0.7s, opacity 0.7s, visibility 0.7s ease;
      }
    }

    &-item {
      &.selected-menu {
        .mobile-select-page__link {
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg.arrow-right {
            min-width: 6px;
            margin-right: 0;
            transform: rotate(90deg);
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -ms-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            transition: all 0.6s ease;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      //&:not(:last-child) {
      //  margin-bottom: 8px;
      //}
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-main;
    background-color: $color-light;
    font-weight: 500;
    font-size: $font-size-main;
    line-height: 20px;
    padding: 10px 13px;
    border: none;
    cursor: pointer;
    width: 100%;

    svg {
      min-width: 14px;
      margin-right: 17px;
      rect {
        fill: $color-light;
      }
      path {
        fill: $color-accent;
      }
    }

    &_active {
      color: $color-main;
      background-color: $color-light;

    }

    @media ($hover) {
      &:hover {
        color: $color-main;
        background-color: $color-light;

        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    color: $color-light;
    font-weight: 800;
    padding: 16px 32px 0 32px;
  }
}