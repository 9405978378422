.info-full-form {
  &__container {
    width: 100%;
    max-width: 747px;
    margin-bottom: 32px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include xs {
      display: block;
    }
  }
  &__field {
    margin-bottom: 32px;
    max-width: 358px;
    width: 48%;

    @include xs {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;
    }
  }
  &__button {
    span {
      display: inline-flex;
      align-items: center;
    }
    &.main-button {
      width: 100%;
      max-width: 360px;
      min-height: 40px;
      font-weight: 500;
      font-size: $font-size-main;
      line-height: 20px;
      svg {
        margin-right: 10px;
      }
    }
  }
}