.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  &_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinner span {
  content: "";
  display: block;
  border-radius: 50%;
  border: 4px solid #fff;
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
