.policy-page {
  &__wrapper-content {
  }

  &__content {
    padding: 96px 0 96px 96px;
    position: relative;
    z-index: 3;
    max-width: 71%;
    @include lt-xl {
      padding: 40px;
      max-width: 100%;
    }
    @include xs {
      padding: 32px;
    }
    @include xxs-max {
      padding: 32px 10px 32px 10px;
    }

    h2 {
      font-size: $font-size-big;
      line-height: 30px;
      font-weight: 800;
      margin-bottom: 24px;
      @include xs {
        font-size: $font-size-medium;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }

    h3 {
      font-size: $font-size-medium;
      line-height: 26px;
      font-weight: 800;
      margin-bottom: 16px;
      @include xs {
        font-size: $font-size-main;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    p {
      font-size: $font-size-main;
      line-height: 24px;
      margin-bottom: 16px;
      @include xs {
        font-size: $font-size-small;
        line-height: 20px;
        margin-bottom: 12px;
      }
    }

    b {
      font-weight: 800;

      &.color {

        color: $color-accent;
      }
    }

    ul {
      padding-left: 24px;
      list-style: none;
      margin: 0;

      li {
        font-size: $font-size-main;
        line-height: 24px;
        margin-bottom: 8px;
        position: relative;

        &:before {
          content: '';
          width: 12px;
          height: 4px;
          border-radius: 2px;
          background-color: $color-accent;
          position: absolute;
          left: -18px;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}