.profile-page__form {
  width: 100%;
  max-width: 747px;
  margin-top: 32px;
  @include xs {
    margin-top: 24px;
  }

  &-field {
    margin-bottom: 32px;
    width: 48%;

    @include xs {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &-pass {
    position: relative;
  }

  &-show-pass {
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    cursor: pointer;
  }

  &-field-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include xs {
      flex-direction: column;
    }
  }

  button.main-button {
    width: 100%;
    max-width: 360px;
    min-height: 40px;
    font-weight: 500;
    font-size: $font-size-main;
    line-height: 20px;

    &.icon {
      justify-content: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}