.sign-up-page__form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //max-width: 430px;
  max-width: 362px;
  width: 100%;
  border-radius: 6px;
  padding: 24px 20px;
  z-index: 3;
  @include lg {
    background-color: $color-light;
    box-shadow: 0px 2px 5px rgba(45, 60, 88, 0.2);
  }

  @include lt-lg {
    max-width: 450px;
    padding: 20px 0;
  }
  @include xs {
    padding: 32px 0;
  }

  &.register {
    .sign-up-page__form_wrapper-field {
      flex-direction: column;

      .sign-up-page__form-field {
        width: 100%;
        margin-bottom: 16px;
      }
    }

    .sign-up-page__form_wrapper-button {
      flex-direction: column;

      button {
        width: 100%;
        justify-content: center;

        .icon-arrow-right {
          position: absolute;
          right: 20px;
        }

        .icon-arrow-left {
          transform: rotate(180deg);
          position: absolute;
          left: 20px;

          svg {
            path {
              fill: $color-accent;
            }
          }
        }

      }

      ._switch-auth-btn {
        order: 2;
        margin-top: 16px;
      }
    }
  }

  h2 {
    margin-bottom: 32px;
    font-weight: 800;
    font-size: $font-size-big;
    line-height: 30px;
    @include xs {
      font-size: $font-size-medium;
      line-height: 22px;
    }
  }

  &-field {
    width: 48%;
    position: relative;
  }

  &_wrapper-field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &-show-pass {
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    cursor: pointer;
  }


  input.sign-up-page__form-input {
    padding: 3px 3px 12px 3px;
    border: none;

    font-size: $font-size-main;
    border-bottom: 1px solid $color-border-main;
    border-radius: 0;

    &.input.no-valid-input {
      border-color: #f34e4e !important;
    }

    &:focus:not(:active) {
      border-bottom: 1px solid $color-accent;
      border-right: 0;
      border-left: 0;
      border-top: 0;
      outline: none;
    }
    &_with-icon {
      padding-right: 22px;
    }
  }

  &_wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.locked {
      pointer-events: none;
      filter: opacity(80%);
    }

    button.main-button {
      width: 48%;
      font-size: $font-size-main;
      min-height: 40px;
      font-weight: 500;

      span {
        font-size: 0px;
      }

      @include xs {
        padding: 10px 15px;
      }
    }
  }

  &_social-button {
    margin-top: 32px;

    h3 {
      font-size: $font-size-main;
      line-height: 20px;
      font-weight: 300;
      color: $color-minor;
      margin-bottom: 16px;
      margin-top: 0;
    }

    button.main-button, a.main-button {
      font-size: $font-size-main;
      min-height: 40px;
      font-weight: 500;
      color: $color-light;
      padding: 10px 10px 10px 13px;
      gap: 11px;
      border: none;

      &.icon {
        justify-content: flex-start;

        .icon-arrow-right {
          position: absolute;
          right: 20px;
        }

        &._twitter {
          background-color: #1da1f2;
          margin-bottom: 8px;
        }

        &._google {
          background-color: #4285f4;
          margin-bottom: 8px;
        }

        &._windows {
          background-color: #2f2f2f;
        }
      }
    }
  }

}

.sign-up-page__login-reg {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;

  &.login {
    justify-content: center;
  }

  &.locked {
    pointer-events: none;
    filter: grayscale(90%) opacity(40%);
  }

  & > button {
    color: $color-main;
    font-size: $font-size-main;
    font-weight: 600;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
