.shop-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  margin-bottom: 24px;
  @include xl-max {
    grid-template-columns: repeat(3, 1fr);
  }
  @include lt-xl {
    padding-bottom: 20px;
  }
  @include lt-lg {
    grid-template-columns: repeat(2, 1fr);
  }
  @include lt-md {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 0;
  }

  &__btn-container {
    text-align: center;
  }

  &__load-more-btn {
    &_loading {
      cursor: default;
      pointer-events: none;

      > span {
        opacity: 0;
      }
    }

    &.main-button {
      display: inline-flex;
      width: auto;
    }
  }

  &__hide-last {
    .product-item:last-of-type {
      display: none;
    }
  }


  &__btn-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &__loader {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    svg g {
      fill: $color-accent
    }
  }
}