.balance-page {

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
      font-size: $font-size-main;
      line-height: 20px;
      cursor: pointer;
      @media ($hover) {
        &:hover {
          opacity: $hover-opacity;
        }
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  .content-page__wrapper {
    @include xs {
      padding: 16px;
    }
  }

  .loader {
    margin-top: 20px;
    text-align: center;
  }
}