.faq-page {
  display: flex;
  width: 100%;
  background-color: $color-light;

  &__question {
    margin: 10px 0;

    &-title {
      font-size: $font-size-accent;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-text {
      font-size: $font-size-medium;
    }
  }
}