.info-page-faq {
  &__wrapper-content {
  }

  &__content {
    padding: 96px 0 96px 96px;
    position: relative;
    z-index: 3;
    max-width: 71%;
    @media screen and (max-width: 1450px) {
      padding: 56px 0 56px 96px;
    }
    @include lt-xl {
      padding: 40px;
      max-width: 100%;
    }
    @include xs {
      padding: 32px;
    }
    @include xxs-max {
      padding: 32px 10px 32px 10px;
    }

    h2 {
      font-size: $font-size-medium;
      line-height: 26px;
      font-weight: 800;
      margin-bottom: 16px;

      &:not(:first-child) {
        margin-top: 48px;
      }
      &.sub-title {
        font-size: 32px;
        line-height: 38px;
        @include xs {
          font-size: $font-size-accent;
          line-height: 22px;
          margin-bottom: 24px;
        }
      }

      @include xs {
        font-size: $font-size-medium;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }

    h3 {
      font-size: $font-size-medium;
      line-height: 26px;
      font-weight: 800;
      margin-bottom: 16px;

      @include xs {
        font-size: $font-size-medium;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }


    p {
      font-size: $font-size-main;
      line-height: 24px;
      margin-bottom: 16px;
      @include xs {
        font-size: $font-size-small;
        line-height: 20px;
        margin-bottom: 12px;
      }
    }

    b {
      font-weight: 800;

      &.color {

        color: $color-accent;
      }
    }

    ul {
      padding-left: 24px;
      list-style: none;
      margin: 0;

      li {
        font-size: $font-size-main;
        line-height: 24px;
        margin-bottom: 8px;
        position: relative;

        &:before {
          content: '';
          width: 12px;
          height: 4px;
          border-radius: 2px;
          background-color: $color-accent;
          position: absolute;
          left: -18px;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }

  &__content-go-chats {
    margin-top: 106px;
    @include xs {
      margin-top: 24px;
    }

    p {
      font-size: $font-size-small;
      line-height: 16px;
      color: $color-minor;
    }

    button.main-button {
      max-width: 168px;
      font-size: $font-size-main;
      line-height: 20px;
      padding: 10px 20px;
    }
  }
}