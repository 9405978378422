$color-main: #000000;
$color-accent: #00aff0;
$color-minor: #9EA9B3;
$color-light: #ffffff;
$color-bright: #f4955f;
$color-attention: #f34e4e;
$color-success: #25AB62;

$color-border-main: #E4E7EA;


$font-size-small: 12px;
$font-size-main: 14px;
$font-size-medium: 16px;
$font-size-accent: 20px;
$font-size-big: 24px;
$font-size-large: 80px;

$line-height-min: 1;
$line-height-main: 1.4;


$font-family-main: 'Manrope', sans-serif;


$hover: 'hover: hover';
$hover-opacity: 0.8