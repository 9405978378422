.loading-main {
  margin: 10px 0;
  -webkit-animation: rotation 1s steps(8) infinite;
  animation: rotation 1s steps(8) infinite;

  &.color-accent {
    path, g, circle {
      fill: $color-accent;
    }
  }
}